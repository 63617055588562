import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";

const AnaltyicsTable = ({ data = [] }) => {
  return (
    <div
      className="w-100"
      style={{
        overflow: "scroll",
        height: 600,
      }}
    >
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Meter Id</th>
            <th scope="col">DCU Id</th>
            <th scope="col">Phase</th>
            <th scope="col">Energy</th>
            <th scope="col">Active Import Energy</th>
            <th scope="col">Active Export Energy</th>
            <th scope="col">Apparent Energy</th>
            <th scope="col">Apparent Power</th>
            <th scope="col">Reactive Power</th>
            <th scope="col">Voltage</th>
            <th scope="col">Frequency</th>
            <th scope="col">Min Voltage</th>
            <th scope="col">Max Voltage</th>
            <th scope="col">Min Current</th>
            <th scope="col">Max Current</th>
            <th scope="col">Power Factor</th>
            <th scope="col">PQ Date Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr key={index}>
              <td scope="row">{index + 1}</td>
              <td>{i.meterid}</td>
              <td>{i.dcuid}</td>
              <td>{i.phase}</td>
              <td>{i.energy}</td>
              <td>{i.act_imp_energy}</td>
              <td>{i.act_exp_energy}</td>
              <td>{i.app_energy}</td>
              <td>{i.app_power}</td>
              <td>{i.rea_power}</td>
              <td>{i.voltage}</td>
              <td>{i.frequency}</td>
              <td>{i.min_vol}</td>
              <td>{i.max_vol}</td>
              <td>{i.min_current}</td>
              <td>{i.max_current}</td>
              <td>{i.pf}</td>
              <td>{moment(i.pqdatetime).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AnaltyicsTable;
