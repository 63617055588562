// import axios from "../../../utils/axios";
import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
// import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
// import ChannelsTable from "../../../components/Submeter/Table/ChannelsTable";
// import TopCards from "../../../components/Submeter/TopCards";
import TopNav from "../../../components/TopNav";
import { color } from "../../../constant/color";
// import { meterController } from "../../../Controller/meterController";
import "./style.css";
import IssueSubmeter from "../../../components/Submeter/IssueSubmeter";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import UpdateSaas from "../../../components/Submeter/UpdateSaas";
import {
  useGetDCUInfoMutation,
  useDeactivateDCUMutation,
  useActivateDCUMutation,
} from "../../../services/device.services";
import { CustomButton } from "../../../components/CustomButton";
import DCUMeterTable from "../../../components/DCU/table/DCUMeterTable";
import AddMeterToDCU from "../../../components/DCU/modals/AddMeterToDCU";
import { ImPower } from "react-icons/im";
import IssueDCUToCompany from "../../../components/DCU/modals/IssueDCUToCompany";
import { errorBlock } from "../../../Controller/errorBlock";
import { useGetCompanyInfoMutation } from "../../../services/company.services";

const DCUInfo = () => {
  let { id } = useParams();
  const [submeterDetails, setSubMeterDetails] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [modal, setModal] = useState(false);
  // const [info, setinfo] = useState(false);
  // const [loading, setloading] = useState(false);
  const [metermodal, setmetermodal] = useState(false);
  const [issuemodal, setissuemodal] = useState(false);
  const [opensaas, setopensaas] = useState(false);
  // const [metainfo, setmetainfo] = useState(false);
  const [getDCU, { data }] = useGetDCUInfoMutation();
  const [getCompanyInfo, { data: companydata }] = useGetCompanyInfoMutation();
  const [
    activateDCU,
    {
      isLoading: loadingActivate,
      error: activateError,
      status: activateStatus,
      data: activateData,
    },
  ] = useActivateDCUMutation();
  const [
    deactivateDCU,
    {
      isLoading: loadingdeActivate,
      error: deactivateError,
      status: deactivateStatus,
      data: deactivateData,
    },
  ] = useDeactivateDCUMutation();

  useEffect(() => {
    getDCU(id);
  }, [refresh]);

  useEffect(() => {
    if (data?.companyalias) {
      getCompanyInfo(data?.companyalias);
    }
  }, [data]);

  const handleStatus = async (e) => {
    try {
      if (e) {
        activateDCU(id);
      } else {
        deactivateDCU(id);
      }
      setRefresh((prev) => !prev);
    } catch (error) {
      errorBlock(error);
    }
  };

  return (
    <Layout>
      <TopNav />
      {issuemodal && (
        <IssueDCUToCompany closeBtn={setissuemodal} refreshBtn={setRefresh} />
      )}
      {modal && (
        <IssueSubmeter
          meterid={id}
          closeBtn={setModal}
          refreshBtn={setRefresh}
        />
      )}
      {metermodal && (
        <AddMeterToDCU closeBtn={setmetermodal} refreshBtn={setRefresh} />
      )}
      {opensaas && (
        <UpdateSaas
          closeBtn={setopensaas}
          refreshBtn={setRefresh}
          meter={submeterDetails}
        />
      )}
      <div className="mx-3 mt-3 p-2">
        <div className="">DCU id: {data?.dcuid}</div>
        <hr />
        <div
          className="row p-2 mx-0 my-3 border d-flex align-items-center justify-content-between bg-white rounded"
          style={{}}
        >
          <div className="col-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="caption">Serial Id</div>
              <h6 className="mt-2">{data?.serialid ?? "-Nil-"}</h6>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="caption">DCU capacity</div>
              <h6 className="mt-2">{data?.devicecapacity ?? 0}</h6>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="caption">DCU model</div>
              <h6 className="mt-2">{data?.devicemodel ?? "-Nil-"}</h6>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="caption">Timezone</div>
              <h6 className="mt-2">{data?.time_zone ?? "-Nil-"}</h6>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-3">
            <div
              className="border p-2 rounded-1 bg-white"
              style={{ height: 120 }}
            >
              <div className="mt-2">
                <p
                  style={{
                    fontSize: 14,
                  }}
                  className="m-0 fw-thin"
                >
                  Company assigned to DCU{" "}
                </p>
                {data?.companyalias ? (
                  <Link
                    to={`/company/${data?.companyalias}`}
                    style={{
                      fontSize: 20,
                      color: color.primary,
                    }}
                    className="fw-semibold text-decoration-underline"
                  >
                    {companydata?.companyname}
                  </Link>
                ) : (
                  "Not assigned to a company yet"
                )}
                {data?.companyalias ? (
                  <div
                    style={{
                      fontSize: 13,
                    }}
                    className="d-flex mt-4 align-items-center justify-content-between "
                  >
                    <div>Timezone</div>
                    <div>{data?.time_zone ?? "-Nil-"}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 13,
                    }}
                    className="d-flex mt-3 w-100 align-items-center justify-content-end"
                  >
                    <CustomButton
                      onClick={() => setissuemodal(true)}
                      className={
                        "w-50 border-none outline-none border-0 text-white p-1 rounded"
                      }
                      styles={{
                        background: "#eee",
                      }}
                    >
                      <p
                        className="m-0 text-black"
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Issue to company
                      </p>
                    </CustomButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* saas expiration */}
          <div className="col-3">
            <div
              className=" border p-2 rounded-1 bg-white"
              style={{ height: 120 }}
            >
              <div className="mt-2">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="">
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="m-0 fw-thin"
                    >
                      Saas expration on DCU{" "}
                    </p>
                    {data?.saasexpirydate ? (
                      <div
                        style={{
                          fontSize: 20,
                        }}
                        className="fw-semibold"
                      >
                        {moment(data?.saasexpirydate).format("YYYY-MM-DD")}
                      </div>
                    ) : (
                      "Not assigned to a company yet"
                    )}
                  </div>
                  <div className="">
                    <FcCalendar size={20} />
                  </div>
                </div>
                <div className="d-none align-items-center justify-content-end">
                  <div
                    style={{
                      fontSize: 13,
                    }}
                    className="d-flex mt-3 w-25 align-items-center justify-content-end "
                  >
                    <CustomButton
                      className={
                        "w-100 border-none outline-none border-0 text-white p-1 rounded"
                      }
                      styles={{
                        background: "#eee",
                      }}
                    >
                      <p
                        className="m-0 text-black"
                        style={{
                          fontSize: 13,
                        }}
                      >
                        Update
                      </p>
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* status */}
          <div className="col-3">
            <div
              className=" border p-2 rounded-1 bg-white"
              style={{ height: 120 }}
            >
              <div className="mt-2">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="">
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="m-0 fw-thin"
                    >
                      Active status of DCU{" "}
                    </p>
                  </div>
                  <div className="">
                    <ImPower size={20} color="green" />
                  </div>
                </div>

                {data?.activestatus ? (
                  <Link
                    to={"/company/ikj"}
                    style={{
                      fontSize: 20,
                      color:
                        data?.activestatus == "active" ? "green" : "tomato",
                    }}
                    className="fw-semibold"
                  >
                    {data?.activestatus[0].toUpperCase() +
                      data?.activestatus.substring(1)}
                  </Link>
                ) : (
                  "Not assigned to a company yet"
                )}
                {data?.activestatus && (
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      style={{
                        fontSize: 13,
                      }}
                      className="d-flex mt-3 w-50 align-items-center justify-content-end "
                    >
                      <CustomButton
                        loading={loadingActivate || loadingdeActivate}
                        onClick={() =>
                          handleStatus(
                            data?.activestatus == "active" ? false : true
                          )
                        }
                        className={
                          "w-100 mb-2 border-none outline-none border-0 text-white p-1 rounded"
                        }
                        styles={{
                          background: "#eee",
                        }}
                      >
                        <p
                          className="m-0 text-black"
                          style={{
                            fontSize: 13,
                          }}
                        >
                          {data?.activestatus == "active"
                            ? "Disactivate DCU"
                            : "Activate DCU"}
                        </p>
                      </CustomButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* date created */}
          <div className="col-3">
            <div
              className=" border p-2 rounded-1 bg-white"
              style={{ height: 120 }}
            >
              <div className="mt-2">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="">
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="m-0 fw-thin"
                    >
                      Date created{" "}
                    </p>
                  </div>
                  <div className="">
                    <FcCalendar size={20} />
                  </div>
                </div>

                <div
                  to={"/company/ikj"}
                  style={{
                    fontSize: 20,
                  }}
                  className="fw-semibold"
                >
                  {moment(data?.datecreated).format("lll")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mx-0">
          <div className=" bg-white shadow-sm p-2 rounded-1">
            <div className="d-flex row align-items-center justify-content-between">
              <div className="col-5">
                <h6 className="m-0">Meters on DCU</h6>
                <p
                  className="m-0 "
                  style={{
                    fontSize: 13,
                  }}
                >
                  List of the meter availabe on DCU
                </p>
              </div>
              <div className="col-2">
                {/* <CustomButton
                  onClick={() => setmetermodal(true)}
                  className={
                    "w-100 border-none outline-none border-0 text-white p-2 rounded"
                  }
                  styles={{
                    background: "#eee",
                  }}
                >
                  <p
                    className="m-0 text-black"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Add meter to DCU
                  </p>
                </CustomButton> */}
              </div>
            </div>
            <hr />
            <div className="">
              <DCUMeterTable data={[]} loading={false} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DCUInfo;
