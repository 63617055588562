import React from "react";
import "./style.css";
import { IoHardwareChipSharp } from "react-icons/io5";
import { HiScale } from "react-icons/hi";
import { RiBuildingFill, RiServerFill } from "react-icons/ri";

const TopCards = ({
  title,
  value,
  iconType,
  bgColor,
  showCompanyInfo,
  active,
  inactive,
  fontSize,
  textcolor,
  showBtn,
  onclick,
}) => {
  return (
    <div className="top-card bg-white shadow-sm rounded-3 p-2 d-flex align-items-center flex-column justify-content-center">
      <div className="card-bottom w-100 d-flex align-items-center justify-content-between">
        <div
          className="card-img d-flex align-items-center justify-content-center"
          style={{
            backgroundColor:
              iconType === "company"
                ? "#99c5ff65"
                : iconType === "submeter"
                ? "#6fed6f33"
                : iconType === "model"
                ? "#a86bfd3b"
                : "#ffb2a44c",
          }}
        >
          {iconType === "company" ? (
            <RiBuildingFill size={25} color={"dodgerblue"} />
          ) : iconType === "submeter" ? (
            <IoHardwareChipSharp size={25} color={"#3a9d3a"} />
          ) : iconType === "model" ? (
            <RiServerFill size={20} color={"#752bdf"} />
          ) : (
            <HiScale size={20} color={"tomato"} />
          )}
        </div>
        <div className="w-75 mx-3 ">
          <div
            className="py-2 text-left"
            style={{
              fontWeight: "bold",
              color: textcolor || "#2b3648",
              fontSize: fontSize || "16px",
            }}
          >
            {value}
          </div>
          <div className="title">{title}</div>
        </div>
      </div>
      {showBtn && (
        <div className="row mt-3 w-100 d-flex align-items-end justify-content-start">
          <div className="col-6" onClick={onclick}>
            <div
              className="border p-1 rounded-1 text-primary text-center border-primary w-100"
              style={{
                fontSize: 11,
              }}
            >
              Update
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopCards;
