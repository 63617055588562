import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";

const APIHeaders = { Version: "0.1", "content-type": "application/json" };
const baseURL = "https://api.headend.chanels.io/";

const credentials = {
  callerid: window.localStorage.getItem("spiral_user"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const devicesAPI = createApi({
  reducerPath: "devicePath",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // create DCU
    createDCU: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/createdcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          dcumodel: data?.selectedmodel,
          serialid: data?.serial,
        },
      }),
    }),
    // create DCU auto
    createDCUAuto: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/createdcuauto",
        method: "POST",
        body: {
          ...credentials,
          prefix: data?.prefix,
          numberofdcus: data?.numberofdcus,
          dcumodel: data?.selectedmodel,
        },
      }),
    }),
    // DCU info
    getDCUInfo: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/getdcuinfo",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data,
        },
      }),
    }),
    // Add meter to DCU
    addMeterToDCU: build.mutation({
      query: (data) => ({
        url: "devicemanagement/meter/addmetertodcu",
        method: "POST",
        body: {
          ...credentials,
          meterid: data?.meterid,
          dcuid: data?.dcuid,
          companyalias: data?.alias,
        },
      }),
    }),
    // Issue DCU to company
    issueDCUToCompany: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/issuedcutocompany",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data?.dcuid,
          saasexpiration: moment(data?.saas).format("YYYY-MM-DD"),
          companyalias: data?.alias,
          // devicecapacity: Number(data?.devicecapacity),
        },
      }),
    }),
    // deactivate DCU
    deactivateDCU: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/deactivatedcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data,
        },
      }),
    }),
    // activate DCU
    activateDCU: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/activatedcu",
        method: "POST",
        body: {
          ...credentials,
          dcuid: data,
        },
      }),
    }),
    // view all the DCU created
    getDCU: build.mutation({
      query: (data) => ({
        url: "/devicemanagement/dcu/getalldcuinfo",
        method: "POST",
        body: {
          ...credentials,
          dcumodel: JSON.stringify(data?.dcumodel) ?? JSON.stringify([]),
          startdatecreated: data?.startdatecreated ?? "",
          enddatecreated: data?.enddatecreated ?? "",
          startdateassigned: data?.startdateassigned ?? "",
          enddateassigned: data?.enddateassigned ?? "",
          startdateexpiry: data?.startdateexpiry ?? "",
          enddateexpiry: data?.enddateexpiry ?? "",
          companyalias:
            JSON.stringify(data?.companyalias) ?? JSON.stringify([]),
          activestatus: data?.activestatus ?? "",
          startcapacity: data?.startcapacity ?? "-1",
          endcapacity: data?.endcapacity ?? "-1",
        },
      }),
    }),
  }),
});

export const {
  useCreateDCUMutation,
  useGetDCUMutation,
  useCreateDCUAutoMutation,
  useGetDCUInfoMutation,
  useAddMeterToDCUMutation,
  useIssueDCUToCompanyMutation,
  useActivateDCUMutation,
  useDeactivateDCUMutation,
} = devicesAPI;
