import axios from "axios";
import { getToken, deleteToken } from "./token";
const controller = new AbortController();
const instance = axios.create({
  // baseURL: "https://api.headend.spiral.systems/",
  baseURL: "https://api.headend.chanels.io/",
});

instance.interceptors.request.use(
  async (config) => {
    try {
      const token = await getToken("spiral_token");
      const user = await getToken("spiral_user");
      config.headers["Version"] = "0.1";
      if (config.data) {
        config.data.sessionid = token;
        config.data.callerid = user;
      }
      config.signal = controller.signal;
      return config;
    } catch (err) {
      // console.log(err, "line 19");
    }
  },
  (error) => {
    // console.log(error, "line 23 axios interceptors");
    // console.log(error.message, "line 23 axios interceptors");
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    console.log(error, "line 47 axios interceptos");
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== "/signin"
    ) {
      deleteToken("spiral_token");
      window.location.href = "/signin";
    } else return Promise.reject(error);
  }
);

export default instance;
