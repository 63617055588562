import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
// import CompnaiesTable from "../../components/Companies/Tables/Companies";
// import TopCards from "../../components/Companies/TopCards";
import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import { color } from "../../constant/color";
import axios from "../../utils/axios";
import { companyController } from "../../Controller/CompanyController";
import "./style.css";
// import AddCompany from "../../components/Companies/AddCompany";
// import MeterModelTable from "../../components/MeterModel/Tables/MeterModelTable";
// import AddModel from "../../components/MeterModel/AddModel";
import AddDCUModel from "../../components/DCU/modals/AddDCUModel";
import DCUModelTable from "../../components/DCU/table/DCUModelTable";
import { errorBlock } from "../../Controller/errorBlock";

const DCUModel = () => {
  // const [tabstate, settabstate] = useState(1);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [panels, setPanels] = useState(0);
  const [count, setCount] = useState(0);
  const [submeter, setSubmeter] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/devicemanagement/dcu/getalldcumodels", {});
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setPanels(
          result.message.body.filter((i) => i.devicetype === "Panel").length
        );
        setSubmeter(
          result.message.body.filter((i) => i.devicetype === "Submeter").length
        );
        setCount(result.message.meta.datapointcount);
        setLoading(false);
      } catch (err) {
        errorBlock(err);
        setLoading(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      <TopNav />
      <div className="mt-3 p-2 bg-white shadow rounded mx-3">
        {modal && <AddDCUModel closeBtn={setModal} refreshBtn={setRefresh} />}
        <div
          className="container-fluid"
          style={{
            marginBottom: 200,
          }}
        >
          <div className="row  d-flex align-items-end justify-content-between">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-end ">
              <div className="">
                <h6 className="m-0">DCU models</h6>
                <p className="m-0">List of DCU models you have created</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-end justify-content-end">
              <div className="rounded" onClick={() => setModal(true)}>
                <Button
                  text={"Add dcu model"}
                  color={color.white}
                  fontSize={"15px"}
                  bgColor={color.primary}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col-12">
              <DCUModelTable
                refreshBtn={setRefresh}
                closeBtn={setModal}
                data={data}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DCUModel;
