import React, { Component, useState } from "react";
import "./style.css";
import { MdDashboard } from "react-icons/md";
import { color } from "../../constant/color";
import { FaBuilding, FaDashcube } from "react-icons/fa";
import { GrServers } from "react-icons/gr";
import AppLogo from "../../assets/images/applogo.jpeg";
import {
  IoHardwareChipSharp,
  IoSettingsSharp,
  IoLogOut,
} from "react-icons/io5";
import { TbMessageShare } from "react-icons/tb";
import { GiMicrochip } from "react-icons/gi";
import { IoIosArrowDown } from "react-icons/io";
import { RiBarChartGroupedFill, RiNotification2Fill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { deleteToken, getToken } from "../../utils/token";
import axios from "../../utils/axios";
const SideNav = () => {
  const [active, setActive] = useState(false);
  const [showdd, setshowdd] = useState(false);
  const [showmodel, setshowmodel] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const modeloptions = [
    { id: 1, title: "Meter model", link: "/metermodels" },
    { id: 2, title: "DCU model", link: "/dcumodels" },
  ];
  const handleLogout = async () => {
    try {
      let token = await getToken("spiral_token");
      let user = await getToken("spiral_user");

      let res = await axios.post("/auth/logout", {
        username: user,
      });

      if (res.data.response != true) {
        toast.error("Failed to logout.");
        return;
      }

      await deleteToken("spiral_token");
      await deleteToken("spiral_user");

      navigate("/signin");
    } catch (err) {
      toast.error(err.message);
    }
  };
  return (
    <div className="side-nav">
      <div className="side-nav__brand mb-4  w-100  d-flex align-items-center justify-content-between">
        <div
          className="d-flex mt-2 pb-2 align-items-start "
          style={{
            borderBottom: "0.5px solid #eee",
          }}
        >
          {/* <div
            className=" overflow-hidden  rounded-full"
            style={{
              width: 30,
              height: 30,
              backgroundPosition: "40%",
              background: `url(${AppLogo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
              borderRadius: "100%",
              marginTop: 1,
            }}
          ></div> */}
          <div className="mx-3">
            <div
              className="font-bold text-white"
              style={{
                lineHeight: 1,
              }}
            >
              Chanels Headend
            </div>
            <p
              className="m-0"
              style={{
                fontSize: 11,
                color: "#eee",
                fontWeight: "lighter",
              }}
            >
              Headend management system
            </p>
          </div>
        </div>
        {/* <div className="side-nav_text mt-2 w-100 text-white text-center">
          Chanels Headend
        </div> */}
      </div>
      <div className="side-nav_items">
        <Link
          to="/"
          className="d-flex align-items-center my-3   px-3 py-2 side-nav__link-cover"
          style={{
            background: location.pathname == "/" ? color.primary : "",
          }}
        >
          <div className="d-flex p-1 align-items-center justify-content-center">
            <FaDashcube
              size={15}
              color={location.pathname == "/" ? color.white : "#a3a3a3"}
            />
          </div>
          <div
            className="side-nav__link px-2 w-75 d-block text-decoration-none"
            style={{
              color: location.pathname == "/" ? color.white : "#a3a3a3",
            }}
          >
            Dashboard
          </div>
        </Link>
        <div
          className="d-flex my-3   px-3 py-2 side-nav__link-cover"
          style={{
            background:
              location.pathname == "/allcompanies" ? color.primary : "",
          }}
        >
          <div className="d-flex p-1 align-items-center justify-content-center">
            <FaBuilding
              size={15}
              color={
                location.pathname == "/allcompanies" ? color.white : "#a3a3a3"
              }
            />
          </div>
          <Link
            to="/allcompanies"
            className="side-nav__link px-2 w-75 d-block text-decoration-none"
            style={{
              background:
                location.pathname == "/allcompanies" ? color.primary : "",
              color:
                location.pathname == "/allcompanies" ? color.white : "#a3a3a3",
            }}
          >
            Companies
          </Link>
        </div>
        <Link
          className="d-flex align-items-center my-3  px-3 py-1 side-nav__link-cover"
          style={{
            background: location.pathname == "/dcu" ? color.primary : "",
          }}
          to="/dcu"
        >
          <div className="p-1">
            <GiMicrochip
              size={18}
              color={location.pathname == "/dcu" ? color.white : "#a3a3a3"}
            />
          </div>
          <div
            className="side-nav__link px-2 w-75 d-block text-decoration-none"
            style={{
              background: location.pathname == "/dcu" ? color.primary : "",
              color: location.pathname == "/dcu" ? color.white : "#a3a3a3",
            }}
          >
            DCU
          </div>
        </Link>
        <Link
          className="d-flex align-items-center my-3  px-3 py-1 side-nav__link-cover"
          style={{
            background: location.pathname.includes("/submeter")
              ? color.primary
              : "",
          }}
          to="/submeter"
        >
          <div className="p-1">
            <GiMicrochip
              size={18}
              color={
                location.pathname.includes("/submeter")
                  ? color.white
                  : "#a3a3a3"
              }
            />
          </div>
          <div
            className="side-nav__link px-2 w-75 d-block text-decoration-none"
            style={{
              background: location.pathname.includes("/submeter")
                ? color.primary
                : "",
              color: location.pathname.includes("/submeter")
                ? color.white
                : "#a3a3a3",
            }}
          >
            Submeters
          </div>
        </Link>
        {/* Models */}
        <div
          onClick={() => setshowmodel((prev) => !prev)}
          className="d-flex align-items-center mt-3 mb-2  px-3 py-1 side-nav__link-cover"
          style={{
            background: location.pathname.includes("model")
              ? color.primary
              : "",
          }}
        >
          <div className="p-1">
            <IoHardwareChipSharp
              size={18}
              color={
                location.pathname.includes("model") ? color.white : "#a3a3a3"
              }
            />
          </div>
          <div
            className="side-nav__link px-2  d-block text-decoration-none"
            style={{
              background: location.pathname.includes("model")
                ? color.primary
                : "",
              marginRight: 14,
              color: location.pathname.includes("model")
                ? color.white
                : "#a3a3a3",
            }}
          >
            Meter Models
          </div>
          <div>
            <IoIosArrowDown size={18} color={"#a3a3a3"} />
          </div>
        </div>
        {/* model options */}
        {showmodel && (
          <div className="">
            {modeloptions.map(({ id, link, title }, index) => (
              <Link
                key={index}
                to={link}
                className="d-flex align-items-center mt-1  px-3 py-2 side-nav__link-cover"
                style={{
                  background: location.pathname.includes(link)
                    ? color.primary
                    : "",
                }}
              >
                <div
                  className="side-nav__link px-4 w-100 d-block text-decoration-none"
                  style={{
                    background: location.pathname.includes(link)
                      ? color.primary
                      : "",
                    color: location.pathname.includes(link)
                      ? color.white
                      : "#a3a3a3",
                  }}
                >
                  {title}
                </div>
              </Link>
            ))}
          </div>
        )}
        <div
          className="d-flex align-items-center my-3  px-3 py-1 side-nav__link-cover"
          style={{
            background: location.pathname == "/analytics" ? color.primary : "",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <RiBarChartGroupedFill
              size={18}
              color={
                location.pathname == "/analytics" ? color.white : "#a3a3a3"
              }
            />
          </div>
          <Link
            to="/analytics"
            className="side-nav__link px-2 w-75 d-block text-decoration-none"
            style={{
              background:
                location.pathname == "/analytics" ? color.primary : "",
              color:
                location.pathname == "/analytics" ? color.white : "#a3a3a3",
            }}
          >
            Analytics
          </Link>
        </div>
        <div
          className="d-flex align-items-center mt-2  px-3 py-1 side-nav__link-cover"
          style={{
            background: location.pathname.includes("/settings")
              ? color.primary
              : "",
          }}
          onClick={() => {
            setshowdd((prev) => !prev);
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <IoSettingsSharp
              size={18}
              color={
                location.pathname.includes("/settings")
                  ? color.white
                  : "#a3a3a3"
              }
            />
          </div>
          <div
            className="side-nav__link px-2 w-100 d-block text-decoration-none d-flex align-items-center justify-content-between"
            style={{
              background: location.pathname.includes("/settings")
                ? color.primary
                : "",
              color: location.pathname.includes("/settings")
                ? color.white
                : "#a3a3a3",
            }}
          >
            <div>Settings</div>
            <div>
              <IoIosArrowDown size={18} color={color.primary} />
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            display: showdd ? "block" : "none",
          }}
        >
          <Link
            to="/settings/notifications"
            className="d-flex align-items-center mt-1  px-3 py-1 side-nav__link-cover"
            style={{
              background: location.pathname.includes("/settings/notifications")
                ? color.primary
                : "",
            }}
          >
            <div
              className="side-nav__link px-4 w-75 d-block text-decoration-none"
              style={{
                background: location.pathname.includes(
                  "/settings/notifications"
                )
                  ? color.primary
                  : "",
                color: location.pathname.includes("/settings/notifications")
                  ? color.white
                  : "#a3a3a3",
              }}
            >
              Notification
            </div>
          </Link>
          <Link
            to="/settings/messages"
            className="d-flex align-items-center mt-1  px-3 py-1 side-nav__link-cover"
            style={{
              background: location.pathname.includes("/settings/messages")
                ? color.primary
                : "",
            }}
          >
            <div
              className="side-nav__link px-4 w-75 d-block text-decoration-none"
              style={{
                background: location.pathname.includes("/settings/messages")
                  ? color.primary
                  : "",
                color: location.pathname.includes("/settings/messages")
                  ? color.white
                  : "#a3a3a3",
              }}
            >
              Messages
            </div>
          </Link>
          <Link
            to="/settings/server"
            className="d-flex align-items-center mt-1  px-3 py-1 side-nav__link-cover"
            style={{
              background: location.pathname.includes("/settings/server")
                ? color.red
                : "",
            }}
          >
            <div
              className="side-nav__link px-4 w-75 d-block text-decoration-none"
              style={{
                background: location.pathname.includes("/settings/server")
                  ? color.red
                  : "",
                color: location.pathname.includes("/settings/server")
                  ? color.white
                  : "#a3a3a3",
              }}
            >
              Server
            </div>
          </Link>
        </div>

        <div
          className="d-flex my-3   px-3 py-2 side-nav__link-cover"
          onClick={handleLogout}
        >
          <div
            className="side-icon d-flex align-items-center justify-content-center"
            style={{
              background: location.pathname == "/logout" ? color.white : "",
              width: 30,
              height: 30,
              borderRadius: "50%",
              // backgroundColor: "#fff",
            }}
          >
            <IoLogOut size={18} color={"#a3a3a3"} />
          </div>
          <Link
            to=""
            className="side-nav__link w-75 d-block text-decoration-none"
            style={{
              color: "#a3a3a3",
            }}
          >
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
